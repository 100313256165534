import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gbaToken: '',
    gbaUser: '',
    errorResponse: "",
    //domain: "https://fthzm2m3h3.execute-api.ap-southeast-1.amazonaws.com/",
    timeZoneSelected: "",
    gbaUserPermissions: [],
  },
  mutations: {
    CHANGE_TIMEZONE(state, timeZoneData) {
      state.timeZoneSelected = timeZoneData
      return state.timeZoneSelected
    }
  },
  actions: {},
  modules: {},
});
