<template>
  <v-app>
    <v-app-bar app color="cyan" dark>
      <v-col cols="12">
        <v-row>
          <v-col cols="2">
            <div class="d-flex align-center" style="margin-right: 10px">
              <h2>
                <v-icon x-large>{{ icon }}</v-icon> {{ $route.name }}
              </h2>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="2" v-if="$route.name != 'Login'">
            <v-select
              label="Time zone"
              dense
              outlined
              hide-details
              :items="timeZone"
              v-model="$store.state.timeZoneSelected"
              @change="changeTimeZone"
            ></v-select
          ></v-col>

          <v-col cols="1" style="text-align: right">
            <v-menu offset-y v-if="$route.name != 'Login'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text dark v-bind="attrs" v-on="on">
                  <span class="mr-2">{{ username }}</span>
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
              </template>
              <v-list>
                <router-link to="dashboard" style="text-decoration: none">
                  <v-list-item link>
                    <v-list-item-title>DASHBOARD</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="profile" style="text-decoration: none">
                  <v-list-item link>
                    <v-list-item-title>PROFILE</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link
                  to="supplier-orders"
                  style="text-decoration: none"
                  v-if="$store.state.gbaUser.indexOf('partner') < 0"
                >
                  <v-list-item link>
                    <v-list-item-title>SUPPLIER ORDERS</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link
                  to="partner-orders"
                  style="text-decoration: none"
                  v-if="$store.state.gbaUser.indexOf('staff') < 0"
                >
                  <v-list-item link>
                    <v-list-item-title>PARTNER ORDERS</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link
                  to="partner-orders-billing"
                  style="text-decoration: none"
                  v-if="$store.state.gbaUser.indexOf('staff') < 0"
                >
                  <v-list-item link>
                    <v-list-item-title>BILLING</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item link @click="signout">
                  <v-list-item-title style="color: red"
                    >LOGOUT</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-main>
      <v-app id="inspire">
        <router-view />
      </v-app>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_8vBdk5PAd",
    userPoolWebClientId: "51e572vhkn38k7tuvsdst697b1",
    mandatorySignIn: true,
  },
});

export default {
  name: "App",

  data: () => ({
    timeZone: ["Asia/Ho_Chi_Minh", "Asia/Shanghai", "America/Los_Angeles"],
    //selected: "",
  }),

  created() {
    this.$root.$refs.app = this;
  },

  computed: {
    icon() {
      var text = "";
      if (this.$route.name == "Orders") {
        text = "mdi-cart";
      }

      if (this.$route.name == "Login") {
        text = "mdi-login";
      }

      if (this.$route.name == "Error") {
        text = "mdi-alert-circle";
      }

      if (this.$route.name == "Profile") {
        text = "mdi-account-box";
      }

      return text;
    },

    username() {
      return this.$store.state.gbaUser;
    },
  },

  methods: {
    changeTimeZone() {
      //this.$store.commit("CHANGE_TIMEZONE", this.selected);
      localStorage.setItem("timeZone", this.$store.state.timeZoneSelected);
      this.$root.$refs.ordersList.loadData();
    },

    async checkToken() {
      var currentSession = null;
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: false });
      } catch (error) {
        console.log("User logged out!");
        this.$store.state.gbaToken = "";
        this.$store.state.gbaUser = "";
        this.$store.state.errorResponse = "";
        this.$store.state.timeZoneSelected = "";
        this.$store.state.gbaUserPermissions = [];
        this.$router.push("/login");
      }

      try {
        currentSession = await Auth.currentSession();
        //console.log("currentSession: ", currentSession);
      } catch (error) {
        console.log("error: ", error);
        this.$store.state.gbaToken = "";
        this.$store.state.gbaUser = "";
        this.$store.state.errorResponse = "";
        this.$store.state.timeZoneSelected = "";
        this.$store.state.gbaUserPermissions = [];
        this.$router.push("/login");
      }

      if (currentSession != undefined) {
        this.$store.state.gbaToken = currentSession.accessToken.jwtToken;
        this.$store.state.gbaUser = currentSession.accessToken.payload.username;
      } else {
        console.log("currentSession-undefined: ", currentSession);
        this.$store.state.gbaToken = "";
        this.$store.state.gbaUser = "";
        this.$store.state.errorResponse = "";
        this.$store.state.timeZoneSelected = "";
        this.$store.state.gbaUserPermissions = [];
        this.$router.push("/login");
      }
    },

    async signout() {
      try {
        this.$store.state.gbaToken = "";
        this.$store.state.gbaUser = "";
        this.$store.state.errorResponse = "";
        this.$store.state.timeZoneSelected = "";
        this.$store.state.gbaUserPermissions = [];
        await Auth.signOut();
        this.$router.push("/login");
        //location.reload();
        await this.checkToken();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async getUserRoles() {
      //console.log("getUserRoles");
      await this.checkToken();

      var response = await axios.get(
        window.domain + "users/" + this.$store.state.gbaUser
      );

      this.$store.state.gbaUserPermissions = response.data.data.permissions;
      // console.log("=======permission: ", this.$store.state.gbaUserPermissions);
    },
  },

  async mounted() {
    await this.getUserRoles();
    this.$store.state.timeZoneSelected = localStorage.getItem("timeZone");
    await this.sleep(1000);
    await this.sleep(1000);
    //console.log("this.selected: ", this.selected);
    await this.checkToken();
  },
};
</script>

<style scoped>
.v-app-bar.v-app-bar--fixed {
  z-index: 0;
}
</style>