import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/supplier-orders",
    name: "Supplier Orders",
    component: () => import("../views/OrdersVendor.vue"),
  },
  {
    path: "/partner-orders",
    name: "Partner Orders",
    component: () => import("../views/OrdersPartner.vue"),
  },
  {
    path: "/partner-orders-billing",
    name: "Billing",
    component: () => import("../views/OrdersPartnerBilling.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "*",
    name: "Error",
    component: () => import("../views/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
