import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//VUETIFY
import vuetify from "./plugins/vuetify";

//COOKIES
import VueCookies from 'vue-cookies'

//AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false;
Vue.use(VueCookies)

Vue.use(VueAxios, axios)

//axios-request
axios.interceptors.request.use((config) => {
  const token = store.state.gbaToken
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    //config.headers.crossdomain = true;
  }
  //console.log('Request: ', config)
  return config;
}, (error) => {
  alert("Something went wrong!")
  console.log("Error: ", error)
  return Promise.reject(error);
})

//axios-response
axios.interceptors.response.use((response) => {
  //console.log('Response API ', response)
  return response;
}, (error) => {
  switch (error.response.status) {
    case 200:
      break;
    case 401:
      store.state.errorResponse = `Not logged in or The user does not have permission`;
      console.log(store.state.errorResponse + ": ", error)
      break;
    case 403:
    case 404:
    case 500:
      store.state.errorResponse = `An error occurred on the server, please check the server.`;
      console.log(store.state.errorResponse + ": ", error)
      // this.goTo(`/exception/${ev.status}`);
      break;
    default:
      alert("Something went wrong!")
      console.log("Error Response: ", error)
      break;
  }

  return Promise.reject(error);
})

window.domain = "https://fthzm2m3h3.execute-api.ap-southeast-1.amazonaws.com/"//Vue.$cookies.get('domainSite')

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
